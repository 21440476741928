<template>
    <div
            class="match-table__title-wrapper"
        >
            <div>{{ $t("STANDINGS") }}</div>
            <div class="display-flex">
            <div
                class="match-table__title-arrow__wrapper tl"
                :class="{ invisible: !rankingTable.isShowArrow }"
                @click="handleLeagueListRankingTable('prev')"
            >
                <svg
                width="6"
                height="10"
                viewBox="0 0 6 10"
                class="match-table__title-arrow__icon"
                xmlns="http://www.w3.org/2000/svg"
                >
                <path
                    d="M5.66542 1.74875L2.42125 5L5.66542 8.25125L4.66667 9.25L0.41667 5L4.66667 0.75L5.66542 1.74875Z"
                />
                </svg>
            </div>
            <div class="match-table__title-country">
                <div class="match-table__title-country-container">
                <span>{{ rankingTable.currentSelectedLeague }}</span>
                </div>
            </div>
            <div
                class="match-table__title-arrow__wrapper"
                :class="{ invisible: !rankingTable.isShowArrow }"
                @click="handleLeagueListRankingTable('next')"
            >
                <svg
                width="6"
                height="10"
                viewBox="0 0 6 10"
                v-show="rankingTable.isShowArrow"
                class="match-table__title-arrow__icon"
                xmlns="http://www.w3.org/2000/svg"
                >
                <path
                    d="M0.334961 8.46017L3.57913 5.20892L0.334961 1.95767L1.33371 0.958923L5.58371 5.20892L1.33371 9.45892L0.334961 8.46017Z"
                />
                </svg>
            </div>
            </div>
    </div>

    <div v-if="isNba" class="nav-pill__wrapper">
        <div class="nav-pill fix-width" @click="handleOption(o)" v-for="(o, index) in option" :key="index"  :class="{ 'selected' : o.selected}">{{ $t(o.name) }}</div>
    </div>
    <div class="match-table__option2-wrapper">
        <div class="nav-pill__wrapper ">
            <div class="nav-pill" @click="handleOption2(o)" v-for="(o, index) in option2" :key="index" :class="{ 'selected' : o.selected}">{{ $t(o.name) }}</div>
        </div>
    </div>
    <div v-if="isNba">
        <div v-for="(o, index) in matchTableList" :key="index">
            <div class="match-table match-table__header" >
                    <div  class="display-flex">
                        <div class="match-table__index">#</div>
                        <div class="match-table__club">              
                            <div>{{ o.title }}</div>
                        </div>
                    </div>
                    <div class="match-table__score">
                        <div>W</div>
                        <div>L</div>
                        <div>PCT</div>
                        <div>L10</div>
                        <div>PF</div>
                        <div>PA</div>
                    </div>   
                
            </div>
            <Navigation :id="o.teamId" :category="'team'" :name="o.teamName"  class="match-table" v-for="(o, index) in o.list" :key="index" >
                <div class="display-flex-center">
                    <div class="match-table__index"  :class="o.promotionColor">{{index +1}}</div>
                    <div class="match-table__club">                   
                        <div class="match-table__club-icon ">
                            <Icon  :logoUrl=o.logo ></Icon>
                            </div>
                        <div>{{o.teamName}}</div>
                    </div>
                </div>
                <div class="match-table__score">
                    <div>{{o.gamesWon === null? '-' : o.gamesWon}}</div>
                    <div>{{o.gamesLost === null? '-' : o.gamesLost}}</div>
                    <div>{{o.gamesPCT === null? '-'  :o.gamesPCT}}</div>
                    <div>{{o.gamesLast10 === null? '-' : o.gamesLast10}}</div>
                    <div>{{o.gamesPointsFor === null? '-' : o.gamesPointsFor}}</div>
                    <div>{{o.gamesPointsAgainst === null? '-' : o.gamesPointsAgainst}}</div>
                </div> 
            </Navigation>
        </div>
    </div>
    <div v-else>
        <div class="match-table match-table__header" >
                <div  class="display-flex">
                    <div class="match-table__index">#</div>
                    <div class="match-table__club">              
                        <div>{{ $t("TEAM") }}</div>
                    </div>
                </div>
                <div class="match-table__score">
                    <div>W</div>
                    <div>L</div>
                    <div>PCT</div>
                    <div>L10</div>
                    <div>PF</div>
                    <div>PA</div>
                </div>   
            
        </div>
        <Navigation :id="o.teamId" :category="'team'" :name="o.teamName"  class="match-table" v-for="(o, index) in matchTableListNotNba" :key="index" >
            <div class="display-flex-center">
                <div class="match-table__index"  :class="o.promotionColor">{{index +1}}</div>
                <div class="match-table__club">                   
                    <div class="match-table__club-icon ">
                        <Icon  :logoUrl=o.logo ></Icon>
                        </div>
                    <div>{{o.teamName}}</div>
                </div>
            </div>
            <div class="match-table__score">
                <div>{{o.gamesWon === null? '-' : o.gamesWon}}</div>
                <div>{{o.gamesLost === null? '-' : o.gamesLost}}</div>
                <div>{{o.gamesPCT === null? '-'  :o.gamesPCT}}</div>
                <div>{{o.gamesLast10 === null? '-' : o.gamesLast10}}</div>
                <div>{{o.gamesPointsFor === null? '-' : o.gamesPointsFor}}</div>
                <div>{{o.gamesPointsAgainst === null? '-' : o.gamesPointsAgainst}}</div>
            </div> 
        </Navigation>
    </div>
    <!-- <div class="match-table__tips-wrapper" v-for="(o, index) in promotionList" :key="index">
        <div class="match-table__tips" >
            <div class="match-table__index" :class="o.color">#</div>
            <div>{{o.value}}</div>
        </div>
    </div> -->
</template>

<script>
import Loading from '@/components/indicator/Loading.vue'
import config from '@/js/config.js'

import {mapGetters,mapActions} from 'vuex'
export default {
    data() {
        return {
            option: [
                {
                    name: "CONFERENCE",
                    isSelected: false,
                    away: [],
                    home: [],
                    total: [],
                    promotionList: []
                },
                {
                    name:  "DIVISION",
                    isSelected: false,
                    away: [],
                    home: [],
                    total: [],
                    promotionList: []
                }
            ],
            option2: [
                {
                    name: "ALL",
                    id: "total",
                    selected: false
                },
                {
                    name: "HOME",
                    id: "home",
                    selected: false
                },
                {
                    name: "AWAY",
                    id: "away",
                    selected: false
                }
            ],
            notNbaOption: {
                away: [],
                home: [],
                total: []
            },
            current: {
                index: 0,
                type: "total"
            },
            rankingTable: {
                isShow: false,
                isShowArrow: true,
                isLoading: false,
                currentSelectedLeagueIndex: 0,
                list: [],
                currentSelectedLeague: "",
            },
            isNba: true,
            matchTableList: [],
            matchTableListNotNba:[],
            promotionList:[]

        }
    },
    components:{
        Loading
    },
    props: {
        matchTableListData: {
            type: Object
        },
    },
    mounted() {
        this.getBasketballLeagueRankingData();
    },
    methods: {
        ...mapActions([
                "getBasketballLeagueRanking",
            ]),
        
        async getBasketballLeagueRankingData() {
             let params = {
                leagueIdList: this.$route?.query?.leagueId ?? "",
            };
            const result = await this.getBasketballLeagueRanking(params);
            this.rankingTable.list = result.result;
         

            this.handleLeagueListRankingTable();
            
            this.handleOption(this.option[0]);
            this.handleOption2(this.option2[0]);
        },
        handleOption(o) {
            this.option.forEach(element => {
                element.selected = false;
            });
            o.selected = !o.selected;

            for (let i =0 ; i<this.option.length; i++) {
                if (this.option[i].name === o.name ) {
                    this.current.index = i
                }
            }
            this.matchTableList = this.option[this.current.index][this.current.type];
            this.promotionList = this.option[this.current.index]["promotionList"];
        },
        handleOption2(o) {
            this.option2.forEach(element => {
                element.selected = false;
            });
            o.selected = !o.selected;
            this.current.type = o.id
            this.matchTableList =  this.option[this.current.index][this.current.type];

            this.matchTableListNotNba = this.notNbaOption[this.current.type]

        },
        handleLeagueListRankingTable(action) {
            let limit = this.rankingTable.list.length - 1;
            let index = 0;
            if (limit === 0) {
                this.rankingTable.isShowArrow = false;
            } else {
                this.rankingTable.isShowArrow = true;
            }
            if (action === "next") {
                if (this.rankingTable.currentSelectedLeagueIndex < limit) {
                    this.rankingTable.currentSelectedLeagueIndex++;
                } else {
                    this.rankingTable.currentSelectedLeagueIndex = 0;
                }
                index = this.rankingTable.currentSelectedLeagueIndex;
            } else if (action === "prev") {
                if (this.rankingTable.currentSelectedLeagueIndex > 0) {
                    this.rankingTable.currentSelectedLeagueIndex--;
                } else {
                    this.rankingTable.currentSelectedLeagueIndex = limit;
                }

                index = this.rankingTable.currentSelectedLeagueIndex;
            } else {
                index = 0;
            }

            this.rankingTable.currentSelectedLeague = this.rankingTable.list[index].leagueName;

            let data = this.rankingTable.list[index];
            this.isNba = data.nba;

            if (data.nba) {
                this.option[0]["away"] = data.conference.away;
                this.option[0]["home"] = data.conference.home;
                this.option[0]["total"] = data.conference.total;
                this.option[0]["promotionList"] = data.conference.promotionList;

                this.option[1]["away"] = data.division.away;
                this.option[1]["home"] = data.division.home;
                this.option[1]["total"] = data.division.total;
                this.option[1]["promotionList"] = data.division.promotionList;

                 this.promotionList = this.option[this.current.index]["promotionList"];
            } else {
                this.notNbaOption["away"] = data.away;
                this.notNbaOption["home"] = data.home;
                this.notNbaOption["total"] = data.total;
                this.matchTableListNotNba = this.notNbaOption[this.current.type];
                this.promotionList = data.promotionList;
            }
   
        },
    },
  

  
}
</script>


<style scoped>
    .match-table__main-group-stage-title {
        font-size: 1.125rem;
        font-weight: 700;
        margin-bottom: 0.5rem;
    }
    .match-table__header-country__wrapper{
            display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: .88rem;
        margin-bottom: .4rem;
    }
    .match-table__header{        
        background-color: #212B30;
    }
    .match-table-wc__header {
        background-color: #212B30;
        font-weight: 700;
        font-size: 0.875rem;
        padding: 0.875rem 0.75rem;
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
    }
    .match-table-wc__wrapper {
        margin-bottom: 1.5rem;
    }
    .match-table-wc__child-wrapper .match-table {
        border-bottom: 0;
        position: relative;
    }
    .match-table-wc__child-wrapper .match-table-row::after {
        content: '';
        width: calc(100% - 1.6rem);
        border-bottom: 1px solid #212B30;
        position: absolute;
        bottom: 0;
    }
    .match-table-wc__child-wrapper .match-table-row-wrapper a:last-of-type .match-table-row::after {
        border-bottom: none;
    }
    .match-table-wc__child-wrapper .match-table-row:hover::after{
        border-bottom: 1px solid var(--bg-hover);
    }
    .match-table {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color:#CFD9DE;
        border-bottom:1px solid #212B30;
        padding: .5rem 0.8rem;
        font-size: .6875rem;
    }

    .match-table.selected{
        background-color: #212B30;
        color: white;
        font-weight: 700;
    }
    .match-table:hover{
      background-color: var(--bg-hover);  
    }
    .match-table__index{
        margin-right: .3rem;
        width: 1.1rem;
    }
    .color-1{
        color:#5CD66C;
    }
    .color-2{
        color:#2E8FEF;
    }
    .color-3{
        color:#f7eb83;
    }
    .color-4{
        color:#ff9800;
    }
    .color-5{
        color:#673ab7;
    }
    .color-p{
        color:#ffc0cb;
    }
    .color-r{
        color: #d81f0d;
    }
    .match-table__club{
        display:flex;
        align-items: center;
        flex:2;
        max-width: 8rem;
    }

    .match-table__club-icon{
        margin-right: .5rem;
    }
    .match-table__score{
        display:flex;
        width:10.5rem;
        justify-content: space-between;
        text-align: center;
    }
    .match-table__score>div{
        flex:1;
        margin: 0 .15rem;
        min-width: 1.5rem;
        font-size: 9.5px;
    }
    .match-table__tips-wrapper{
        background-color: var(--color-theme);
        color: #CFD9DE;
    }
    .match-table__tips{
        display: flex;
        flex-direction: row;
        margin: 0 .8rem;
        padding: .5rem 0;
        font-size: .7rem;
    }
    .fix-width{
        width: 50%;
    }
    .match-table__option2-wrapper{
        display: flex;
        justify-content: flex-end;
    }
   .match-table__title-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 0.88rem;
        margin-bottom: 0.5rem;
    }
    .match-table__title-arrow__wrapper {
        width: 2rem;
        text-align: right;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .match-table__title-arrow__icon {
        fill: white;
    }
    .match-table__title-arrow__wrapper:hover .match-table__title-arrow__icon {
        fill: var(--color-hover);
    }
    .match-table__title-arrow__wrapper.tl {
        text-align: left;
    }
    .match-table__title-country {
        display: flex;
        width: 12rem;
        line-height: 1rem;
    }
    .match-table__title-country-container {
        transition: all 0.3s;
        min-width: 100%;

        font-size: 0.7rem;
        text-align: center;
    }
    @media (max-width: 768px) {
        .match-table__main-group-stage-title {
            font-size: 0.875rem;
        }
    }
</style>
