<template>
  <a target="_blank" class="match-container" v-if="!isMobile">
    <!-- <div>{{ level2.opponents[0].name }}</div> -->
    <div class="home-match__score-card__wrapper" :class="{'basketball-temp__score-card__wrapper' : currentSportType === 'basketball'}">
      <div class="btn-fav-wrapper">
        <div class="btn-fav"></div>
      </div>
      <div class="home-match__status">
        <span class="font-s">{{ $t("FT") }}</span>
      </div>
      <div class="home-match__1-wrapper">
        <div class="display-flex home-match__1-container">
          <div class="display-flex-center font-s">
            <div class="icon-league home-match__icon-team">
              <img
                src="../../../../static/images/team_logo_default.png"
                alt="logo"
              />
            </div>
            <span class="mr-03rem">Team A</span>
            <!-- <span v-if="level3.isFavTeam" class="icon-fav tiny"></span> -->
            <!-- <img
                    class="img-contain"
                    v-if="level3.penaltyWin"
                    src="../../static/images/label/label_pen_win.png"
                  /> -->
          </div>
          <div>
            <div class="home-match__icon-team match-detail__score font-s">
              -
            </div>
          </div>
        </div>
        <div class="display-flex home-match__1-container">
          <div class="display-flex-center font-s">
            <div class="icon-league home-match__icon-team">
              <img
                src="../../../../static/images/team_logo_default.png"
                alt="logo"
              />
            </div>
            <span class="mr-03rem">Team B</span>
            <!-- <span v-if="level3.isFavTeam" class="icon-fav tiny"></span> -->
            <!-- <img
                    class="img-contain"
                    v-if="level3.penaltyWin"
                    src="../../static/images/label/label_pen_win.png"
                  /> -->
          </div>
          <div>
            <div class="home-match__icon-team match-detail__score font-s">
              -
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="home-match__actions-card__wrapper">
      <!-- <div class="home-match__icon-match"></div> -->
      <!-- @click.prevent="toggleStatsPopover(level2)" -->
      <!--  v-show="currentSportType === 'football'" -->
      <div class="home-match__icon-wrapper" @click.prevent v-show="false">
        <div class="home-match__icon-stats ">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            width="14px"
            height="14px"
            viewBox="0 0 14 14"
            version="1.1"
          >
            <path
              style="stroke: none; fill-rule: nonzero; fill-opacity: 1;fill: #35454D;"
              d="M 0.46875 4.386719 L 3.269531 4.386719 L 3.269531 13.535156 L 0.46875 13.535156 Z M 5.695312 0.46875 L 8.308594 0.46875 L 8.308594 13.535156 L 5.695312 13.535156 Z M 10.921875 7.933594 L 13.535156 7.933594 L 13.535156 13.535156 L 10.921875 13.535156 Z M 10.921875 7.933594 "
            />
          </svg>
        </div>
        <!-- <StatsContainerPopover
                  :matchId="level2.matchId"
                  v-if="level2.isShowStatsPopover"
                ></StatsContainerPopover> -->
      </div>
      <!-- <div
              v-if="level2.containLiveStream"
              class="home-match__icon-wrapper"
            >
              <div class="home-match__icon-livevideo"></div>
            </div> -->
      <!--  :class="{'phase1-end-column': currentSportType === 'basketball'}" -->
      <div class="home-match__icon-wrapper    phase1-end-column">
        <div class="home-match__icon-highlight"></div>
      </div>
    </div>
    <!--  v-show="currentSportType === 'football'" -->
    <div class="home-match__static-card__wrapper" v-show="false">
      <div class="display-flex-center flex-wrap">
        <div class="home-match__static-card__score display-flex-center">
          -
        </div>
        <div class="home-match__static-card__score display-flex-center">
          -
        </div>
        <div class="home-match__static-card__score display-flex-center">
          -
        </div>
        <div class="home-match__static-card__score display-flex-center">
          -
        </div>
        <div class="home-match__static-card__score display-flex-center">
          -
        </div>
        <div class="home-match__static-card__score display-flex-center">
          -
        </div>
      </div>
    </div>
  </a>
  <div class="home-match__container fav" v-else>
    <!-- currentSportType === 'football' -->
    <a target="_blank" class="home-match__card home-match__card-odds" v-if="false">
      <div class="display-flex-center w-100">
        <div class="btn-fav-wrapper">
          <div class="btn-fav"></div>
        </div>
        <div class="w-100">
          <div class="display-flex w-100">
            <div class="home-match__card-odds-content">
              <div class="home-match__detail">
                <div class="home-match__detail-opponent">
                  <div class="icon-league home-match__icon-team">
                    <img
                      src="../../../../static/images/team_logo_default.png"
                      alt="logo"
                    />
                  </div>

                  <span class="mr-03rem">Team A</span>
                </div>

                <div class="display-flex">
                  <div class="home-match__detail-score">
                    -
                  </div>
                </div>
              </div>
              <div class="home-match__detail">
                <div class="home-match__detail-opponent">
                  <div class="icon-league home-match__icon-team">
                    <img
                      src="../../../../static/images/team_logo_default.png"
                      alt="logo"
                    />
                  </div>
                  <span class="mr-03rem">Team B</span>
                </div>

                <div class="display-flex">
                  <div class="home-match__detail-score">
                    -
                  </div>
                </div>
              </div>
            </div>

            <div class="home-match__static-card__wrapper">
              <div class="display-flex-center flex-wrap">
                <div class="home-match__static-card__score display-flex-center">
                  -
                </div>
                <div class="home-match__static-card__score display-flex-center">
                  -
                </div>
                <div class="home-match__static-card__score display-flex-center">
                  -
                </div>
                <div class="home-match__static-card__score display-flex-center">
                  -
                </div>
                <div class="home-match__static-card__score display-flex-center">
                  -
                </div>
                <div class="home-match__static-card__score display-flex-center">
                  -
                </div>
              </div>
            </div>
          </div>
          <div class="home-match__card-odds-bottom">
            <div class="home-match__card-odds-time">
              <span>{{ $t("FT") }}</span>
            </div>
            <div class="display-flex-center">
              <!-- <div class="home-match__icon-match__mobile"></div> -->
              <div class="home-match__icon-wrapper">
                <div class="home-match__icon-stats">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="14px"
                    height="14px"
                    viewBox="0 0 14 14"
                    version="1.1"
                  >
                    <path
                      style="stroke: none;fill-rule: nonzero;fill-opacity: 1;fill: #35454D;"
                      d="M 0.46875 4.386719 L 3.269531 4.386719 L 3.269531 13.535156 L 0.46875 13.535156 Z M 5.695312 0.46875 L 8.308594 0.46875 L 8.308594 13.535156 L 5.695312 13.535156 Z M 10.921875 7.933594 L 13.535156 7.933594 L 13.535156 13.535156 L 10.921875 13.535156 Z M 10.921875 7.933594 "
                    />
                  </svg>
                </div>
              </div>
              <!-- <div
                v-if="level2.containLiveStream"
                class="home-match__icon-wrapper"
              >
                <div
                  class="home-match__icon-livevideo__mobile"
                  @click="handleHighlight(o, level2, $event)"
                ></div>
              </div> -->
              <div class="home-match__icon-wrapper">
                <div class="home-match__icon-highlight__mobile"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a>

    <!--  v-else-if="currentSportType === 'basketball'" -->
    <a target="_blank" class="home-match__card home-match__card-odds">
      <div class="display-flex-center w-100">
        <div class="btn-fav-wrapper">
          <div class="btn-fav"></div>
        </div>
        <div class="phase1-match-status text-centered">FT</div>
        <div class="w-100">
          <div class="display-flex w-100     display-flex-align-center">
            <div class="home-match__card-odds-content home-match__card-odds-content-phase1">
              <div class="home-match__detail">
                <div class="home-match__detail-opponent">
                  <div class="icon-league home-match__icon-team">
                    <img
                      src="../../../../static/images/team_logo_default.png"
                      alt="logo"
                    />
                  </div>

                  <span class="mr-03rem">Team A</span>
                </div>

                <div class="display-flex">
                  <div class="home-match__detail-score">
                    -
                  </div>
                </div>
              </div>
              <div class="home-match__detail">
                <div class="home-match__detail-opponent">
                  <div class="icon-league home-match__icon-team">
                    <img
                      src="../../../../static/images/team_logo_default.png"
                      alt="logo"
                    />
                  </div>
                  <span class="mr-03rem">Team B</span>
                </div>

                <div class="display-flex">
                  <div class="home-match__detail-score">
                    -
                  </div>
                </div>
              </div>
            </div>

            <div class="home-match__icon-wrapper phase1-end-column">
              <div class="home-match__icon-highlight__mobile"></div>
            </div>

            <div class="home-match__static-card__wrapper" v-if="false">
              <div class="display-flex-center flex-wrap">
                <div class="home-match__static-card__score display-flex-center">
                  -
                </div>
                <div class="home-match__static-card__score display-flex-center">
                  -
                </div>
                <div class="home-match__static-card__score display-flex-center">
                  -
                </div>
                <div class="home-match__static-card__score display-flex-center">
                  -
                </div>
                <div class="home-match__static-card__score display-flex-center">
                  -
                </div>
                <div class="home-match__static-card__score display-flex-center">
                  -
                </div>
              </div>
            </div>
          </div>
          <div class="home-match__card-odds-bottom" v-if="false">
            <div class="home-match__card-odds-time">
              <span>{{ $t("FT") }}</span>
            </div>
            <div class="display-flex-center">
              <!-- <div class="home-match__icon-match__mobile"></div> -->
              <div class="home-match__icon-wrapper">
                <div class="home-match__icon-stats">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="14px"
                    height="14px"
                    viewBox="0 0 14 14"
                    version="1.1"
                  >
                    <path
                      style="stroke: none;fill-rule: nonzero;fill-opacity: 1;fill: #35454D;"
                      d="M 0.46875 4.386719 L 3.269531 4.386719 L 3.269531 13.535156 L 0.46875 13.535156 Z M 5.695312 0.46875 L 8.308594 0.46875 L 8.308594 13.535156 L 5.695312 13.535156 Z M 10.921875 7.933594 L 13.535156 7.933594 L 13.535156 13.535156 L 10.921875 13.535156 Z M 10.921875 7.933594 "
                    />
                  </svg>
                </div>
              </div>
              <!-- <div
                v-if="level2.containLiveStream"
                class="home-match__icon-wrapper"
              >
                <div
                  class="home-match__icon-livevideo__mobile"
                  @click="handleHighlight(o, level2, $event)"
                ></div>
              </div> -->
              <div class="home-match__icon-wrapper">
                <div class="home-match__icon-highlight__mobile"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import config from "@/js/config.js";

export default {
  props: ["data"],
  data() {
    return {
      s3ImgUrl: config.s3ImgUrl,
    };
  },
  mounted() {},
  computed: {
    ...mapGetters([
      "isMobile",
      "currentSportType",
    ]),
  },
};
</script>

<style scoped>
.phase1-end-column {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
.home-match__status {
  margin-right: 0.5rem;
  width: 1.6rem;
  display: flex;
  align-items: center;
}
.home-match__1-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.home-match__1-container {
  justify-content: space-between;
  /* margin-bottom: 0.2rem; */
}
.match-wrapper {
  margin-bottom: 1.2rem;
  width: 100%;
}
.match-container {
  display: flex;
  margin-bottom: 0.5rem;
  position: relative;
}
.match-container:hover .home-match__score-card__wrapper {
  background-color: var(--bg-hover);
}
.match-container:hover .home-match__static-card__wrapper {
  background-color: var(--bg-hover);
}
.match-container:hover
  .home-match__actions-card__wrapper
  .home-match__icon-wrapper {
  background-color: var(--bg-hover);
}
.home-match__static-card__wrapper {
  background-color: #35454d;
  /* padding: 0.5rem 0.25rem 0.2rem; */
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 6.25rem;
  align-items: center;
}
.home-match__score-card__wrapper {
  display: flex;
  background-color: var(--color-theme);
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  width: 35rem;/*25rem;*/
  padding: 0.5rem 0;
  border-left: 0.4rem solid transparent;
}
.basketball-temp__score-card__wrapper {
  width: 35rem !important;
}
.home-match__score-card__wrapper.no-odds-match {
  border-radius: 0.5rem;
}
.home-match__actions-card__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.live-selected {
  border-left: 0.4rem solid var(--bg-selected);
  padding-left: 0.5rem;
}
.match-detail {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.match-detail__score {
  width: 1rem;
  text-align: center;
}
.home-match__static-card__score {
  padding: 0 0.2rem;
  font-size: 0.6rem;
  width: 1.5rem;
  height: 1.5rem;
  text-align: center;
}

.text-centered {
  text-align: center;
}
.phase1-match-status {
  width: 3rem;/* Phase 2-2rem */
  margin: 0 0.5rem;/* Phase 2-0 0.813rem */
}
.home-match__card-odds-content-phase1 {
  padding: 0.5rem 0 0.5rem 0.1rem  !important;
}
@media (max-width: 768px) {
  .home-match__container {
    min-width: 90vw;
  }
}
</style>
